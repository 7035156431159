<template>
  <NavQuestion
    :heading="heading"
    :number="'7'"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <InputOptionSmall
        :letter="'a'"
        :option="'£' + charityMinAmount"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-donate-1k.png'"
        :selected="data.amount === charityMinAmount"
        @selected="setAmount(charityMinAmount)"
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'b'"
        :option="'£5,000'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-donate-5k.png'"
        :selected="data.amount === alternativeAmount"
        @selected="setAmount(alternativeAmount)"
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'c'"
        :option="otherAmountLabel"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-donate-amount.png'"
        :selected="
          data.amount !== alternativeAmount &&
          data.amount !== charityMinAmount &&
          data.amount !== null
        "
        @selected="show.modal = true"
      ></InputOptionSmall>
      <CharityAmountModal
        v-show="show.modal"
        @close="show.modal = false"
        @change="data.amount = $event"
        :minAmount="charityMinAmount"
        :initialAmount="data.amount"
      ></CharityAmountModal>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="backTo"
        v-bind:center="false"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import InputOptionSmall from '@/common/ui/InputOptionSmall'
import CharityAmountModal from './Modals/CharityAmountModal'
import { http } from '@/services'

export default {
  name: 'ProfileCharityAmount',
  components: {
    NavQuestion,
    NavQuestionButtons,
    InputOptionSmall,
    CharityAmountModal
  },
  watch: {
    charity: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value) {
          this.data.charity_name = value.name
          this.data.charity_number = value.charity_number
        } else {
          this.data.charity_name = null
          this.data.charity_number = null
        }
      }
    },
    charityGift: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value) {
          this.data.id = value.id
          this.data.amount = value.amount
        } else {
          this.data.id = null
          this.data.amount = null
        }
      }
    }
  },
  computed: {
    background() {
      if (this.start) return 'bg-personal-details-cookie'
      return 'bg-personal-details'
    },
    isValidated() {
      if (
        this.data.amount &&
        this.data.charity_name &&
        this.data.charity_number
      )
        return true
      return false
    },
    checkoutBasket() {
      return this.$store.getters.checkoutBasket
    },
    charity() {
      return this.checkoutBasket.coupon.charity
    },
    charityMinAmount() {
      if (this.charity) return this.charity.min_amount
      return ''
    },
    charityGift() {
      return this.$store.getters.giftCharity.find(
        (gift) => gift.charity_protected
      )
    },
    charityDistribution() {
      return this.$store.getters.charities.find(
        (charity) => charity.charity_protected
      )
    },
    heading() {
      return 'How much would you like to gift?'
    },
    subHeading() {
      if (this.charity) {
        return (
          'You can gift any amount from £' +
          this.charity.min_amount +
          " upwards. The amount is 'index linked' meaning it will be adjusted to take account of inflation over the years. The legacy will be paid only " +
          'after any debts, funeral costs and other expenses have been paid. If you are making a Will with your partner the gift will be added to both Wills and paid only once you have both passed away.'
        )
      }
      return ''
    },
    start() {
      return this.$router.history.current.name === 'WriteMyWillCharityAmount'
    },
    forwardTo() {
      if (this.start) return '/write_my_will/address'
      return '/profile/address'
    },
    backTo() {
      if (this.start) return '/write_my_will/charity_option'
      return '/profile/charity_option'
    },
    otherAmountLabel() {
      if (
        this.data.amount &&
        this.data.amount !== this.charityMinAmount &&
        this.data.amount !== this.alternativeAmount
      )
        return '£' + this.data.amount.toLocaleString()
      return 'Other Amount'
    }
  },
  beforeRouteLeave(to, from, next) {
    this.save()
    next()
  },
  data() {
    return {
      show: {
        modal: false
      },
      data: {
        id: null,
        amount: null,
        rpi: true,
        charity_name: null,
        charity_number: null,
        charity_protected: true,
        gift_before_partner: true,
        gift_after_partner: false
      },
      alternativeAmount: 5000
    }
  },
  methods: {
    setAmount(value) {
      this.data.amount = value
    },
    save() {
      if (this.data.id) this.patch()
      else this.post()
    },
    patch() {
      http
        .patch('/wills/api/gift_charity', this.data)
        .then((response) => {
          this.$store.commit('giftCharityAdd', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
      this.removeCharityDistribution()
    },
    post() {
      http
        .post('/wills/api/gift_charity', this.data)
        .then((response) => {
          this.$store.commit('giftCharityAdd', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
      this.removeCharityDistribution()
    },
    removeCharityDistribution() {
      if (this.charityDistribution) {
        this.$store.dispatch(
          'deleteCharityFromServer',
          this.charityDistribution.id
        )
      }
    }
  }
}
</script>
