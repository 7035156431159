<template>
  <BaseModalSmall
    @close="close"
    v-bind:isValidated="validateForm"
    @confirm="confirm"
  >
    <template v-slot:header>Other Amount</template>
    <template v-slot:body>
      <div class="input-container">
        <input
          v-model="amount"
          :min="minAmount"
          type="number"
          placeholder="Amount"
          class="input-standard-grey validation"
          id="amount"
        />
        <label for="amount">Amount</label>
      </div>
    </template>
    <template v-slot:button> Update </template>
  </BaseModalSmall>
</template>

<script>
import BaseModalSmall from '@/common/ui/BaseModalSmall'

export default {
  name: 'CharityAmountModal',
  props: ['initialAmount', 'minAmount'],
  components: {
    BaseModalSmall
  },
  watch: {
    initialAmount: {
      deep: true,
      immediate: true,
      handler(value) {
        this.amount = value
      }
    }
  },
  computed: {
    validateForm() {
      if (this.amount && this.amount >= this.minAmount) return true
      return false
    }
  },
  data() {
    return {
      amount: null
    }
  },
  methods: {
    confirm() {
      this.$emit('change', this.amount)
      this.close()
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>
